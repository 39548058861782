<template>
 <div
  class="flex gap- items-center text-xs px-4 py-2 rounded-md focus:outline-none bg-gray-400 text-white w-full text-left">
  <div class="pb-1 gap-">
   <div class="flex gap-2 items-center">
    <!-- <label
     for="location"
     class="col-start-1 flex gap-1 items-center capitalize text-xs font-medium"
     >{{ $t("callqueueTable.start") }}</label
    > -->
    <div class="flex gap-2">
     <span class="flex gap-1 items-center pt-1"
      ><svg
       xmlns="http://www.w3.org/2000/svg"
       class="h-4 w-4"
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor">
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg
     ></span>
     <div class="flex gap-1 items-center">
      <div>
       <select
        v-model="timeStart.dateHours"
        id="location"
        name="location"
        class="text-xs mt-1 text-gray-700 block not-sr-only py-1 w-auto px-1 border-transparent bg-gray-100 rounded-md">
        <option v-for="time in this.timeHours" :key="time" :value="time">
         {{ time }}h
        </option>
       </select>
      </div>
      <span class="self-center"> : </span>
      <div>
       <select
        v-model="timeEndStr"
        id="location"
        name="location"
        class="text-xs mt-1 text-gray-700 block not-sr-only py-1 w-auto px-1 border-transparent bg-gray-100 rounded-md">
        <option
         v-for="time in this.timeHours"
         :key="time"
         :value="time + ':00'">
         {{ time }}h
        </option>
        <option value="23:59">23:59h</option>
       </select>
      </div>
     </div>
    </div>
    <div class="pt-1">
     <button
      @click="clearTimeSelector = !clearTimeSelector"
      class="inline-flex items-center px-1 py-1 capitalize rounded border border-gray-300 bg-gray-200 text-xs text-gray-600 hover:bg-gray-300 hover:text-gray-700">
      <!-- {{ $t("dashboards.clear") }} -->
      <font-awesome-icon icon="clock-rotate-left" class="px-1 h-4 w-4" />
     </button>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
 name: "TimeSelector",
 props: ["formatDateToTime", "elements"],
 components: {},
 data() {
  return {
   clearTimeSelector: false,
   timeStart: { dateHours: "00", dateMin: "00" },
   timeEnd: { dateHours: "23", dateMin: "00" },
   day: "",
   hour: "",
   time: [],
   timeHours: [],
   timeStartStr: "00:00",
   timeEndStr: "23:59",
  };
 },
 methods: {
  async helpersTime() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/helpers/time/hours`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.parseTime(res.data);
   } catch (error) {
    console.error(error);
   }
  },
  parseTime(time) {
   for (let index = 0; index < time.length; index++) {
    const element = time[index];
    this.time.push(element.slice(0, -6));
   }

   this.timeHours = this.time.reduce((unique, o) => {
    if (!unique.some(obj => obj === o)) {
     unique.push(o);
    }
    return unique;
   }, []);
  },
  setTimeFromState() {
   console.log("this.pbxTimeSelected", this.pbxTimeSelected);
   this.timeStart.dateHours = this.pbxTimeSelected[0]?.slice(0, 2);
   this.timeEnd.dateHours = this.pbxTimeSelected[1]?.slice(0, 2);
   this.timeStartStr = this.pbxTimeSelected[0];
   this.timeEndStr = this.pbxTimeSelected[1];
  },
 },
 mounted() {
  this.helpersTime();
  this.setTimeFromState();
 },
 watch: {
  timeStart: {
   handler: function (val) {
    var timeStart = val.dateHours + ":" + val.dateMin;
    this.timeStartStr = timeStart;
    if (
     this.timeEndStr &&
     val.dateHours.length > 0 &&
     parseInt(this.timeStartStr.slice(0, 2)) <
      parseInt(this.timeEndStr.slice(0, 2))
    ) {
     let timeArray = [];
     timeArray.splice(0, 1, timeStart);
     timeArray.splice(1, 1, this.timeEndStr);
     this.$store.dispatch("pbxTimeSelected", timeArray);
     this.$emit("timeSelector", timeArray);
    }
   },
   deep: true,
  },
  timeEndStr: {
   handler: function (val) {
    console.log(val);
    if (this.timeStartStr && this.timeEndStr) {
     if (
      parseInt(this.timeEndStr.slice(0, 2)) >
      parseInt(this.timeStartStr.slice(0, 2))
     ) {
      let timeArray = [];
      timeArray.splice(0, 1, this.timeStartStr);
      timeArray.splice(1, 1, val);
      this.$store.dispatch("pbxTimeSelected", timeArray);
      this.$emit("timeSelector", timeArray);
     }
    }
   },
   deep: true,
  },
  clearTimeSelector: function (val) {
   if (this.timeStartStr || this.timeEndStr) {
    this.timeStart.dateHours = "00";
    this.timeStart.dateMin = "00";
    this.timeEnd.dateHours = "23";
    this.timeEnd.dateMin = "59";
    this.timeStartStr = "00:00";
    this.timeEndStr = "23:59";
    //this.$store.dispatch("pbxTimeSelected", null);
    //this.$emit("timeSelector", []);
   }
  },
 },
 computed: {
  ...mapGetters(["pbxTimeSelectedEnd", "pbxTimeSelected"]),
 },
};
</script>

<style></style>
